<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {

    watch: {
        $route(to, from) {
            if (to.path == '/Guarantee/shenhe' || to.path=="/personalUser" || to.path=="/finance/detail" || to.path=="/finance/companyDetail" ||to.path=="/exxpress" ||to.path=="/kuaidi" ||to.path=="/zhaobiao/detail" ||to.path=="/daihouguanli/detail" ||to.path=="/diaocha/detail" ) {
                this.$store.dispatch("AsyicOPENSHOW", 2);
            } else if(to.path=="/homepage/homepage"){
                this.$store.dispatch("AsyicOPENSHOW", 9);
            }else if(to.path=="/personal"){
                this.$store.dispatch("AsyicOPENSHOW", 8);
            }else if(to.path=="/msg/index"){
                this.$store.dispatch("AsyicOPENSHOW", 7);
            }else if(to.path=="/msg/daiban"){
                this.$store.dispatch("AsyicOPENSHOW", 11);
            } else{
                this.$store.dispatch("AsyicOPENSHOW", 1);
            }
        }
    },
}
</script>
<style lang="scss">
@import url('./utils/css/common.scss');
.aksjhdkajhsdkasd{
    margin: 0;
    padding: 0
}
* {
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

img {
    vertical-align: top;
    border: none
}

/*清除默认样式*/
html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    // overflow: hidden;
}

#app {
    width: 100%;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

ul,
ol {
    list-style: none;
}

img {
    border: none;
    vertical-align: middle;
}

a {
    text-decoration: none;
    color: #232323;
}

table {
    border-collapse: collapse;
    table-layout: fixed;
}

input,
textarea {
    outline: none;
    border: none;
}

textarea {
    resize: none;
    overflow: auto;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
.body{
    padding: 0 10px;
}
</style>
