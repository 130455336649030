import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/homepage/homepage",
    children: [
      //首页
      {
        path: "homepage/homepage",
        name: "homepage",
        component: () => import("../views/homepage/HomepageView.vue"),
      },
      //  工程信息 //采购公告
      {
        path: "information/Purchase",
        name: "Purchase",
      },
      //公开招标
      {
        path: "information/Purchase/gkzb",
        name: "gkzb",
        component: () => import("../views/information/Purchase/GkzbView.vue"),
      },
      //竞争性谈判公告
      {
        path: "information/Purchase/negotiation",
        name: "negotiation",
        component: () =>
          import("../views/information/Purchase/NegotiationView.vue"),
      },
      //竞争性磋商公告
      {
        path: "information/Purchase/consultations",
        name: "consultations",
        component: () =>
          import("../views/information/Purchase/ConsultationsView.vue"),
      },
      //邀请招标公告
      {
        path: "information/Purchase/invitation",
        name: "invitation",
        component: () =>
          import("../views/information/Purchase/InvitationView.vue"),
      },
      //询价公告
      {
        path: "information/Purchase/inquiry",
        name: "inquiry",
        component: () =>
          import("../views/information/Purchase/InquiryView.vue"),
      },
      //单一来源公告
      {
        path: "information/Purchase/source",
        name: "source",
        component: () => import("../views/information/Purchase/SourceView.vue"),
      },
      //资格预审
      {
        path: "information/Purchase/Prequalification",
        name: "Prequalification",
        component: () =>
          import("../views/information/Purchase/PrequalificationView.vue"),
      },
      //  工程信息 //结果公告
      {
        path: "information/result",
        name: "result",
      },
      {
        path: "information/result/bid",
        name: "bid",
        component: () => import("../views/information/result/BidView.vue"),
      },
      {
        path: "information/result/deal",
        name: "deal",
        component: () => import("../views/information/result/DealView.vue"),
      },

      //员工管理
      {
        path: "staff/account",
        name: "account",
        component: () => import("../views/staff/AccountView.vue"),
      },

      {
        path: "staff/jurisdiction",
        name: "jurisdiction",
        component: () => import("../views/staff/JurisdictionfView.vue"),
      },

      //保函管理

      {
        path: "Guarantee/apply",
        name: "apply",
        component: () => import("../views/Guarantee/ApplyView.vue"),
      },
      {
        path: "Guarantee/list",
        name: "list",
        component: () => import("../views/Guarantee/ListView.vue"),
      },
      {
        path: "Guarantee/guarantee",
        name: "guarantee",
        component: () => import("../views/Guarantee/GuaranteeView.vue"),
      },
      // 财务管理
      {
        path: "finance/payment",
        name: "payment",
        component: () => import("../views/finance/PaymentView.vue"),
        meta: { title: "公司列表" },
      },
      {
        path: "finance/mineList",
        name: "mineList",
        component: () => import("../views/finance/mineList.vue"),
        meta: { title: "个人列表" },
      },
      {
        path: "finance/refund",
        name: "refund",
        component: () => import("../views/finance/RefundView.vue"),
      },
      {
        path: "finance/invoice",
        name: "invoice",
        component: () => import("../views/finance/InvoiceView.vue"),
      },
      // 经办人
      {
        path: "qualifications/qlist",
        name: "qlist",
        component: () => import("../views/qualifications/QlistView.vue"),
        meta: { title: "提成收入" },
      },
      {
        path: "qualifications/examine",
        name: "examine",
        component: () => import("../views/qualifications/ExamineView.vue"),
      },
      {
        path: "qualifications/zhichu",
        name: "zhichu",
        component: () => import("../views/qualifications/zhichu.vue"),
        meta: { title: "提成支出" },
      },
      {
        path: "qualifications/dzzp",
        name: "dzzp",
        component: () => import("../views/qualifications/dzzp.vue"),
        meta: { title: "发票列表" },
      },
      {
        path: "Guarantee/shenhe",
        name: "shenhe",
        component: () => import("../views/Guarantee/shenhe.vue"),
        meta: { title: "保函详情" },
      },
      {
        path: "user/userList",
        name: "userList",
        component: () => import("../views/user/userList.vue"),
        meta: { title: "保函详情" },
      },
      // 个人资料
      {
        path: "/personalUser",
        name: "PersonalUserView",
        component: () => import("../views/PersonalUserView.vue"),
        meta: { title: "个人资料" },
      },
      {
        path: "/personal",
        name: "PersonalView",
        component: () => import("../views/PersonalView.vue"),
        meta: { title: "企业资料" },
      },

      //工程进度
      {
        path: "/daihouguanli/jindu",
        name: "daihouguanli/jindu",
        component: () => import("../views/daihouguanli/jindu.vue"),
        meta: { title: "工程进度" },
      },
      {
        path: "/daihouguanli/detail",
        name: "daihouguanli/detail",
        component: () => import("../views/daihouguanli/detail.vue"),
        meta: { title: "工程进度" },
      },
      {
        path: "/daihouguanli/list",
        name: "daihouguanli/list",
        component: () => import("../views/daihouguanli/list.vue"),
        meta: { title: "工程进度" },
      },
      {
        path: "/daihouguanli/jiebaoList",
        name: "daihouguanli/jiebaoList",
        component: () => import("../views/daihouguanli/jiebaoList.vue"),
        meta: { title: "解保列表" },
      },
      {
        path: "/daihouguanli/xubaoList",
        name: "daihouguanli/xubaoList",
        component: () => import("../views/daihouguanli/xubaoList.vue"),
        meta: { title: "续保列表" },
      },
      {
        path: "/daihouguanli/qibao",
        name: "daihouguanli/qibao",
        component: () => import("../views/daihouguanli/qibao.vue"),
        meta: { title: "弃保列表" },
      },
      {
        path: "/finance/detail",
        name: "finance/detail",
        component: () => import("../views/finance/detail.vue"),
        meta: { title: "客户详情" },
      },
      {
        path: "/finance/companyDetail",
        name: "finance/companyDetail",
        component: () => import("../views/finance/companyDetail.vue"),
        meta: { title: "施工单位详情" },
      },
      {
        path: "/exxpress",
        name: "exxpress",
        component: () => import("../views/exxpress.vue"),
        meta: { title: "施工单位详情" },
      },
      {
        path: "/kuaidi",
        name: "kuaidi",
        component: () => import("../views/kuaidi.vue"),
      },
      {
        path: "/zhaobiao/zhaobiao",
        name: "zhaobiao",
        component: () => import("../views/zhaobiao/zhaobiao.vue"),
      },
      {
        path: "/zhaobiao/zhongbiao",
        name: "zhongbiao",
        component: () => import("../views/zhaobiao/zhongbiao.vue"),
      },
      {
        path: "/zhaobiao/detail",
        name: "zhaobiao/detail",
        component: () => import("../views/zhaobiao/detail.vue"),
      },
      //系统通知
      {
        path: "/msg/index",
        name: "msg/index",
        component: () => import("../views/msg/index.vue"),
        meta: { title: "系统通知" },
      },
      //待办事项
      {
        path: "/msg/daiban",
        name: "msg/daiban",
        component: () => import("../views/msg/daiban.vue"),
        meta: { title: "待办事项" },
      },
      //调查报告
      {
        path: "/diaocha/baogaoList",
        name: "/diaocha/baogaoList",
        component: () => import("../views/diaocha/baogaoList.vue"),
        meta: { title: "调查报告" },
      },
      {
        path: "/diaocha/detail",
        name: "diaocha/detail",
        component: () => import("../views/diaocha/detail.vue"),
        meta: { title: "调查报告详情" },
      },
    ],
  },
  {
    path: "/login",
    name: "loginView",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "registerView",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/PersonalRealname",
    name: "PersonalRealname",
    component: () => import("../views/PersonalRealname.vue"),
  },

  //详情页
  {
    path: "/details",
    name: "DetailsView",
    component: () => import("../views/DetailsView.vue"),
  },
  {
    path: "/BidSecurity/SelectALot",
    name: "SelectALot",
    component: () => import("../views/BidSecurity/SelectALot.vue"),
  },
  //2
  {
    path: "/BidSecurity/financialInstitution",
    name: "financialInstitution",
    component: () => import("../views/BidSecurity/financialInstitution.vue"),
  },
  {
    path: "/BidSecurity/Submission",
    name: "SubmiSsion",
    component: () => import("../views/BidSecurity/Submission.vue"),
  },
  //4
  {
    path: "/BidSecurity/bidFrom",
    name: "bidFrom",
    component: () => import("../views/BidSecurity/bidFrom.vue"),
  },
  //5
  {
    path: "/BidSecurity/ElectronicProtocol",
    name: "ElectronicProtocol",
    component: () => import("../views/BidSecurity/ElectronicProtocol.vue"),
  },
  //6
  {
    path: "/BidSecurity/BidPayment",
    name: "BidPayment",
    component: () => import("../views/BidSecurity/BidPayment"),
  },
  //7
  {
    path: "/BidSecurity/puBlic",
    name: "puBlic",
    component: () => import("../views/BidSecurity/puBlic.vue"),
  },
  //实名认证
  {
    path: "/RealName/PersonalRealname",
    name: "PersonalRealname",
    component: () => import("../views/RealName/PersonalRealname.vue"),
  },
  {
    path: "/RealName/RealnameInformation",
    name: "RealnameInformation",
    component: () => import("../views/RealName/RealnameInformation.vue"),
  },
];
const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (
    to.path !== "/login" &&
    to.path !== "/register" &&
    to.path !== "/PersonalRealname" &&
    !sessionStorage.getItem("token")
  ) {
    next("/login");
  } else {
    next();
  }

  // if (to.name == 'login') {
  // 	console.log(to.name);
  // }
  // next()
});

export default router;
