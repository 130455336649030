import request from "@/utils/request"



//个人中心
export const grzx = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/index/userCenter",
    });
  };
  //退出登录
export const out = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/index/logout",
    });
  };
// 企业资质
export const zizhi = () => {

    return request({
      method: "POST",
      url: "/company/zizhi",
    });
  };
  // 企业业绩
export const yeji = () => {

    return request({
      method: "POST",
      url: "/company/yeji",
    });
  };
  //公章使用记录
  export const seal = () => {

    return request({
      method: "POST",
      url: "/index/gongsizhang",
    });
  };
  //法人章使用记录
  export const corporate = () => {

    return request({
      method: "POST",
      url: "/index/farenzhang",
    });
  };
  //修改密码
  export const modify = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/index/password",
    });
  };

